<!--
 * @Author: 武东超
 * @Date: 2022-11-07 08:58:25
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-02-02 10:46:58
 * @FilePath: /cloud_campus_Front-end/cloud_campus_Front-end/school_end/src/components/scrollWrapper/TeachingResearchCourseScheduleTemp/CourseScheduleView/DayList.vue
 * @Description: 课程表 日 列表
-->
<template>
    <div class="day-list">
        <template v-if="this.dayList[0]">
            <table-th wh="100%">
                <div class="day-title">
                    <div class="day-label">
                        {{ this.dayList[0].label }}
                    </div>
                </div>
            </table-th>
            <day-list-item  class="day-list-item" :class="index%2==1 ? 'active':''"
                v-for="(item, index) of this.dayList[0].child"
                :key="index"
                :data="item"
                :daylist="dayList"
                :idx="index"
                :isTourClass="isTourClass"
                :courseList="courseList"
                :type="type"
                :courseScheduleDetailSubjectsList="courseScheduleDetailSubjectsList"
                :courseScheduleDetailTeachersList="courseScheduleDetailTeachersList"
                :queryList="queryList"
                @changeCheck="changeCheck(item)"
            >
            </day-list-item>
        </template>
    </div>
</template>
<script>
import TableTh from './Sub/TableTh.vue';
import DayListItem from './DayListItem.vue';
export default {
    name: "DayList",
    components: {
        TableTh,
        DayListItem
    },
    props: {
        dayList: {
            type: Array,
            default: function () {
                return [{
                    label: '',
                    child: []
                }]
            }
        },
        isTourClass: String,
        courseList: Array,
        type: String,
        courseScheduleDetailSubjectsList: Array,
        courseScheduleDetailTeachersList: Array,
        queryList: Object
    },
    // computed: {
    //     dayObj () {
    //         console.log(this.dayList[0],'this.dayList[0]')
    //         return this.dayList[0]
    //     }
    // },
    mounted(){
console.log("天这是什么数据",this.dayList)
    },
    methods:{
        changeCheck(item){
            this.$set(item,'checkState',!item.checkState)
            this.$eventDispatch('changeCheck', {item:item })
        }
    }
}
</script>

<style lang="scss" scoped>
    .day-list {
        flex: 1;
        .day-list-item.active{
            background-color: #EDF4FA;
        }
        .day-title {
            width: 100%;
            box-sizing: border-box;
            padding: 0 51px 0 32px;
            display: flex;
            justify-content: space-between;
            .day-label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                // color: #808487;
                color: #ffffff;
            }
            .option {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #F56C6C;
                cursor: pointer;
            }
        }
    }
</style>