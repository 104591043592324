<!--
 * @Author: 武东超
 * @Date: 2022-11-07 09:04:03
 * @LastEditors: xwn 3299704183@qq.com
 * @LastEditTime: 2023-07-03 09:46:53
 * @FilePath: /cloud_campus_Front-end/cloud_campus_Front-end/school_end/src/components/scrollWrapper/TeachingResearchCourseScheduleTemp/CourseScheduleView/DayListItem.vue
 * @Description: 列表日
-->
<template>
    <div class="day-list-layout">
        <div class="day-list-item" >
            <div class="item" v-if="data.subjectName">
                <div class="item-left">
                    <div class="check-state" v-if="data.checkState"  @click.stop="changeCheckClick(data)">    
                        <img src="@/assets/images/select-course.png" alt="" srcset="">
                    </div>
                    <div class="check-state" v-else  @click.stop="changeCheckClick(data)">    
                        <img src="@/assets/images/nomal-course.png" alt="" srcset="">
                    </div>
                    <el-tooltip  effect="dark" :content="data.subjectName" placement="top-start">
                        <span class="subjectname">
                            {{ data.subjectName }}
                            <!-- 马克思列宁主要马克思列宁主要 -->
                        </span>
                    </el-tooltip>
                    <el-tooltip  effect="dark" :content="data.teacherName" placement="top-start">
                        <span class="teachername">
                            ({{ data.teacherName }})
                            <!-- (马克思列宁主要马克思列宁主要) -->
                        </span>
                    </el-tooltip>
                    <div class="day-info" @click="detailDay">
                        <div class="classcenter">
                            <el-tooltip  effect="dark" :content="data.className" placement="top-start">
                       
                                <div class="classname"> {{ data.className }}</div>
                    </el-tooltip>
                           
                            <span style="padding: 0px 8px;">|</span>
                            <span class="repeat">{{ data.repeatClass | repeatClassName }}</span>
                            <span style="padding: 0px 8px;">|</span>
                            <span v-if="isTourClass != '0'" :class="['class-patrol', {current: data.isTourClass == '0'}]" @click.stop="tourDetails">{{ data.isTourClass | isTourClassName }}</span>
                        </div>
                    </div>
                </div>
                
                
                <div class="option-list" v-if="showError(data)">
                    <span class="edit" @click="edit">编辑</span>
                    <span class="del" @click="del">删除</span>
                    <span style="padding: 0px 30px;">|</span>
                    <span class="delAllCourses" @click="delAllCourses">清除</span>
                </div>
            </div>
            <div v-else class="add-course-schedule" @click="dataAddCourse">
                <i class="el-icon-plus"></i>
                <span class="add">添加课程</span>
            </div>
            <!-- <div v-else class="add-course-schedule">
                <span class="add">
                    <img :src="prohibitAdding" alt="img" />
                </span>
            </div> -->
        </div>
    </div>
</template>
<script>
import TeachingResearchCourseScheduleTempFilter from '@/filters/TeachingResearchCourseScheduleTemp.js'
export default {
    name: "DayListItem",
    filters: TeachingResearchCourseScheduleTempFilter,
    props: {
        data: Object,
        daylist:Array,
        isTourClass: String,
        idx: Number,
        courseList: Array,
        type: String,
        courseScheduleDetailSubjectsList: Array,
        courseScheduleDetailTeachersList: Array,
        queryList: Object,
    },
    data(){
        return{
            operateShow:false
        }
    },
    computed: {
        prohibitAdding () {
            return require('@/assets/images/prohibit-adding.png')
        }
    },
    methods: {
        showError (time) {
            return time._timestamp = new Date().getTime()
        },
        detailDay () {
            let detailDayData={
                data:this.data,
                daylist:this.daylist[0],
                idx:this.idx
            }
            this.$eventDispatch('detailDay', detailDayData)
            // this.$eventDispatch('detailDay', this.data,this.daylist,this.idx)
        },
        edit () {
            this.$eventDispatch('edit', this.data)
        },
        dataAddCourse () {
            let detailDayData={
                data:this.data,
                daylist:this.daylist[0],
                idx:this.idx
            }
            this.$eventDispatch('dataAddCourse', detailDayData)
        },
        tourDetails () {
            this.$eventDispatch('tourDetails', this.data)
        },
        changeCheckClick(item){
            // item.checkState = !item.checkState
            // this.$emit('changeCheck', {item:item , idx:this.idx})
            // this.$eventDispatch('changeCheck', {item:item })
            this.$emit('changeCheck')
        },
        del () {
            let _this = this;
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 确认
                _this.$eventDispatch('del', {data: this.data, type: '1'})
            }).catch(() => {
                // 取消
            });

        },
        delAllCourses () {

            let text,
                subjectName = [],
                teachName = [],
                _this = this;

            this.getSubjectsName(this.courseScheduleDetailSubjectsList, subjectName);
            this.getTeacherName(this.courseScheduleDetailTeachersList, teachName);

            switch (this.type) {
                case '1': // 班级
                    text = `确认删除${this.data.className}的全部${subjectName[0]}课程吗？`
                break;
                case '2': // 教师
                    // text = `确认删除${teachName[0]}的全部${subjectName[0]}课程吗？`
                    text = `确认删除${teachName[0]}的全部课程吗？`
                break;
                default:
                break;
            }

            this.$confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 确认
                _this.$eventDispatch('delAllCourses', {data: this.data, type: '2'})
            }).catch(() => {
                // 取消
            });
        },
        getSubjectsName (list, subjectName) {
            list.forEach((item) => {
                if(this.data.subjectId == item.id) {
                    subjectName.push(item.name) ;
                }

                if (item.children && item.children.length > 0) {
                    this.getSubjectsName (item.children, teachName)
                }
            })
        },
        getTeacherName (list, teachName) {
            list.forEach((item) => {
                if(this.data.classTeacherId == item.id) {
                    teachName.push(item.name);
                }

                if (item.children && item.children.length > 0) {
                    this.getTeacherName (item.children, teachName)
                }
            })
        },        
    }
}
</script>

<style lang="scss" scoped>
        .day-list-layout:hover{
        border: 2px dashed #499DF2;
        cursor: pointer;
        border-radius: 2px;
    } 
    .day-list-layout {
        
        box-sizing: border-box;
        .day-list-item {
            .item {
                height: 106px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .item-left{
                    display: flex;
                    justify-content: flex-start;;
                    align-items: center;
                    .subjectname{
                        max-width: 100px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: #363B40;
                        font-size: 20px;
                        margin-left: 30px;
                    }
                    .teachername{
                        max-width: 100px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: #6D7073;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                    .day-info{
                        margin-left: 120px;
                        color: #6D7073;
                        font-size: 14px;
                        // background-color: rebeccapurple;
                        .classcenter{
                            display: flex;
                            justify-content: flex-start;;
                            align-items: center;
                            .class-patrol {
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #499DF2;
                            }
                            .classname{
                                max-width: 110px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    
                }
                .option-list{
                        color: #E63A2E;
                        font-size: 14px;
                        .edit{
                            color:#3C7FFF;
                            margin-right: 48px;
                        }
                        .delAllCourses{
                            margin-right: 48px;
                        }
                }
                .check-state{
                 margin-left: 30px;
                    img{
                        height: 16px;
                        width: 16px;
                    } 
                }

            
            }
            .add-course-schedule {
                height: 106px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .el-icon-plus {
                    color: #C8CACC;
                }

                .add {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #B6BABF;
                }
            }

            .add-course-schedule:hover {
                .el-icon-plus {
                    color: #499DF2;
                }
                .add {
                    color: #499DF2;
                }
            }       
        
        }
    }
</style>
